/* eslint-disable */
import { getOptions, isBuying, isPartners } from '@/utils'
import axios from 'axios'

export type FieldOption = 'advertisers' | 'geos' | 'promos'

export async function lookupByField(
  ref: any,
  value: string,
  update: any,
  field: FieldOption,
) {
  const options = await getOptions(ref[field], value, '/api/' + field)
  update(() => {
    ref[field] = options
  })
}

export async function lookupAdvertiser(
    ref: any,
    value: string,
    update: any,
) {
  const options = await getOptions(ref.advertisers, value, '/api/' + 'advertisers')
  update(() => {
    ref.advertisers = options.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupOfferPromos(
    ref: any,
    value: string,
    update: any,
    offerId: number
) {
  const path = `/api/promos/offer?offerId=${offerId}`
  const options = await getOptions(ref.promos, value, path)
  update(() => {
    ref.promos = options
  })
}

export async function lookupPromos(
    ref: any,
    value: string,
    update: any,
) {
  const path = isBuying(ref) ? '/api/buying-landings' : '/api/promos'
  const options = await getOptions(ref.promos, value, path)
  update(() => {
    ref.promos = options
  })
}

export async function lookupGroups(
    ref: any,
    value: string,
    update: any,
    field: string
) {
  const options = await getOptions(ref.groups, value, '/api/groups/' + field)
  update(() => {
    ref.groups = options
  })
}

export async function lookupManagers(
  ref: any,
  value: string,
  update: any,
) {
  // @ts-ignore: Unreachable code error
  const admins: any = await getOptions(ref.admins, value, '/api/admins/managers')
  const adminsOptions = (admins as any)?.map((admin: any) => ({
    ...admin,
    email: 'ID:' + admin.id + ' — ' + admin.credentials.email,
  }))
  update(() => {
    ref.admins = adminsOptions as never[]
  })
}

export async function lookupAffiliates(
  ref: any,
  value: string,
  update: any,
) {
  const buyingFilters = ref.$storage.getStorageSync('buyingFilters') || {}
  const extraParams = isBuying(ref) &&
  ref.$route.path.match(/campaign(s\/|s)$/g) && 'department' in buyingFilters
      ? { department: buyingFilters.department }
      : {}
  const apiPath = isBuying(ref)
    ? 'buying'
    : isPartners(ref)
    ? 'partners'
    : 'all'
  const affiliates = await getOptions(
    ref.affiliates,
    value,
    '/api/affiliates/list/' + apiPath,
      [],
      extraParams,
  )
  // @ts-ignore: Unreachable code error
  const affiliatesOptions: any = (affiliates as any)?.map((affiliate: any) => ({
    ...affiliate,
    email: !isPartners(ref) ? `#${affiliate.id}  ` + (affiliate.name || affiliate.credentials.email) : `#${affiliate.id}  ` + affiliate.credentials.email,
  }))
  update(() => {
    ref.affiliates = affiliatesOptions as never[]
  })
}

export async function lookupMobileApps(
  ref: any,
  value: string,
  update: any,
  ids: number[] = [],
) {
  const apps = await getOptions(ref.apps as [], value, '/api/tds-apps/', ids)
  update(() => {
    ref.apps = apps.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupOffers(
  ref: any,
  value: string,
  update: any,
  ids: number[] = [],
  extraParams: any = {},
  isList = false,
) {
  const apiPath = isBuying(ref) ? 'buying-' : ''
  const offers = await getOptions(
    ref.offers,
    value,
    `/api/${apiPath}offers/` + ((ids.length && isBuying(ref) || isList) ? 'list' : ''),
    ids,
    extraParams,
  )
  update(() => {
    ref.offers = offers.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupLandingsForBuying(
    ref: any,
    value: string,
    update: any,
) {
  const landings = await getOptions(
      ref.landings as [],
      value,
      '/api/buying-landings',
  )
  update(() => {
    ref.landings = landings.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupGeos(
  ref: any,
  value: string,
  update: any,
  ids: number[] = [],
  notIncludeIds: number[] = [],
  customExtraParams: any = {},
) {
  const extraParams: any = ref.$route.path.match(/offers\/./g) || ref.$route.path.match(/mobile-apps\/./g)
    ? { ...customExtraParams }
    : { byRewards: true, ...customExtraParams }
  if (ref.$route.path === '/offers') {
    extraParams.isOffersPage = true
  }
  const geos = await getOptions(
      ref.geos as [],
      value,
      '/api/geos',
      ids,
      extraParams,
      notIncludeIds
  )
  update(() => {
    ref.geos = geos
  })
}

export async function lookupRedirectDomains(
  ref: any,
  value: string,
  update: any,
  ids: number[] = [],
) {
  const redirectDomains = await getOptions(
    ref.redirectDomains as [],
    value,
    '/api/redirect-domains',
    ids,
  )
  update(() => {
    ref.redirectDomains = redirectDomains.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupCampaigns(
  ref: any,
  value: string,
  update: any,
) {
  const buyingFilters = ref.$storage.getStorageSync('buyingFilters') || {}
  const extraParams = isBuying(ref) && ref.$route.path.includes('campaigns') && 'department' in buyingFilters
      ? { department: buyingFilters.department }
      : {}
  const apiPath = isBuying(ref) ? 'buying-' : isPartners(ref) ? '' : 'tds-'
  const campaigns = await getOptions(
    ref.campaigns as [],
    value,
    `/api/${apiPath}campaigns`,
      [],
      extraParams,
  )
  update(() => {
    ref.campaigns = campaigns.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupRotations(
    ref: any,
    value: string,
    update: any,
) {
  const apiPath = isBuying(ref) ? 'buying-' : isPartners(ref) ? '' : 'tds-'
  const campaigns = await getOptions(
      ref.rotations as [],
      value,
      `/api/${apiPath}rotations`,
  )
  update(() => {
    ref.rotations = campaigns.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupAdvertisers(
  ref: any,
  value: string,
  update: any,
) {
  const advertisers = await getOptions(
    ref.advertisers as [],
    value,
    '/api/advertisers',
    [ref.offer.advertiserId as number],
  )
  update(() => {
    ref.advertisers = advertisers.map((r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`
    }))
  })
}

export async function lookupOfferCategories(
  ref: any,
  value: string,
  update: any,
  ids: number[] = [],
  extraParams: any = {},
) {
  const categories = await getOptions(
    ref.categories as [],
    value,
    '/api/offer-categories',
    ids,
    extraParams,
  )
  update(() => {
    ref.categories = categories
  })
}

export async function lookupTrafficSources(
  ref: any,
  value: string,
  update: any,
) {
  const params: any = {}
  if (value) {
    params.search = value
  }
  const apiPath = isBuying(ref) ? '/api/traffic-sources' : '/api/traffic-sources/partners'
  const trafficSources = (await axios.get(apiPath, { params }))
    .data

  update(() => {
    ref.trafficSources = trafficSources
  })
}
