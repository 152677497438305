import { isDefined, RowValue, formatDate, formatValue } from '@/utils'

export function getRedirectEventsColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата создания',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'updatedAt',
      label: 'Дата обновления',
      field: 'updatedAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'installId',
      label: 'ID инсталла',
      field: 'installId',
      align: 'left',
    },
    {
      name: 'geoName',
      label: 'Гео',
      field: 'geoName',
      align: 'left',
    },
    {
      name: 'mobileAppName',
      label: 'Приложение',
      field: 'mobileAppName',
      align: 'left',
      sortable: true,
    },
    {
      name: 'affiliateEmail',
      label: 'Аффилейт',
      field: 'affiliateEmail',
      align: 'left',
    },
    {
      name: 'installPrice',
      label: 'Цена инсталла, $',
      field: 'installPrice',
      align: 'center',
      sortable: true,
      format: (val: RowValue) => formatValue(val, 2, true),
    },
    {
      name: 'url',
      label: 'Ссылка редиректа на оффер',
      field: 'url',
      align: 'left',
    },
    {
      name: 'id',
      label: 'Клик ID в TDS',
      field: 'id',
      align: 'left',
    },
    {
      name: 'utmParams',
      label: 'Параметры, UTM метки',
      field: 'utmParams',
      align: 'left',
      format: (val: RowValue) => JSON.stringify(val),
    },
    {
      name: 'blockParams',
      label: 'Блок параметры клоаки',
      field: 'blockParams',
      align: 'left',
      format: (val: RowValue) => String(isDefined(val)),
    },
    // {
    //   name: 'request',
    //   label: 'Запрос в TDS',
    //   field: 'request',
    //   align: 'left',
    //   format: (val: RowValue) => JSON.stringify(val),
    // },
  ]
}

export function getMobileAppPostbackColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата / Время',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    { name: 'url', label: 'Ссылка', field: 'url', align: 'left' },
    {
      name: 'tdsRedirectEventId',
      label: 'Клик ID в TDS',
      field: 'tdsRedirectEventId',
      align: 'left',
    },
    {
      name: 'status',
      label: 'Статус',
      field: 'status',
      align: 'left',
      format: (val: RowValue) => isDefined(val),
    },
    {
      name: 'body',
      label: 'Body',
      field: 'body',
      align: 'left',
      format: (val: RowValue) => JSON.stringify(val),
    },
  ]
}

export function getTdsPostbackColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата / Время',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'geoName',
      label: 'Гео',
      field: 'geoName',
      align: 'left',
    },
    {
      name: 'mobileAppName',
      label: 'Приложение',
      field: 'mobileAppName',
      align: 'left',
      sortable: true,
    },
    { name: 'url', label: 'Ссылка', field: 'url', align: 'left' },
    {
      name: 'installId',
      label: 'ID инсталла (uuid)',
      field: 'installId',
      align: 'left',
    },
    {
      name: 'tdsRedirectEventId',
      label: 'Клик ID в TDS',
      field: 'tdsRedirectEventId',
      align: 'left',
    },
  ]
}

export function getBlackListLogColumns() {
  return [
    {
      name: 'createdAt',
      label: 'Дата / Время',
      field: 'createdAt',
      align: 'left',
      sortable: true,
      format: (val: string) => formatDate(val),
    },
    {
      name: 'id',
      label: 'ID инсталла',
      field: 'installId',
      align: 'left',
    },
    {
      name: 'package',
      label: 'Приложение',
      field: 'package',
      align: 'left',
    },
    {
      name: 'geoCode',
      label: 'Гео',
      field: 'geoCode',
      align: 'left',
    },
    {
      name: 'blockParams',
      label: 'Блок параметры клоаки',
      field: 'blockParams',
      align: 'left',
      format: (val: RowValue) => String(isDefined(val)),
    },
    {
      name: 'isValid',
      label: 'Оффер (Да/Нет)',
      field: 'isValid',
      align: 'left',
      format: (val: any) =>
        val === false
          ? '🔴 Заглушка'
          : val === true
          ? '🟢 Оффер'
          : '⚪️ Нет данных',
    },
    {
      name: 'offerUrl',
      label: 'Ссылка',
      field: 'offerUrl',
      align: 'left',
    },
    {
      name: 'utmParams',
      label: 'Query параметры',
      field: 'utmParams',
      align: 'left',
      format: (val: RowValue) => JSON.stringify(val),
    },
  ]
}
