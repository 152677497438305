
import { defineComponent } from 'vue'
import DatePicker from '../date-picker/DatePicker.vue'
import {
  FieldOption,
  lookupByField,
  lookupAffiliates,
  lookupOfferCategories,
  lookupMobileApps,
  lookupManagers,
  lookupTrafficSources,
  lookupGroups,
  lookupOffers,
  lookupCampaigns,
  lookupPromos,
  lookupAdvertiser,
} from '@/utils/lookups'
import axios from 'axios'
import { initFilterOptions, getFiltersData } from '@/utils/filters'
import FiltersDialog from '../dialogs/FiltersDialog.vue'
import { FiltersType } from '@/utils/request'
import { confirmDialog, isAdminTds, isBuying, isPartners } from '@/utils'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'Filters',
  components: { DatePicker, FiltersDialog },
  props: ['reset', 'currentFilters', 'change', 'tab', 'export'],
  async created() {
    if (this.$route.query.advertiserId) {
      this.advertisers = (await axios.get('/api/advertisers')).data?.map(
        (r: any) => ({
          ...r,
          name: `#${r.id} ${r.name}`,
        }),
      )
    }
    if (this.$route.query.offerId) {
      if (isBuying(this)) {
        this.offers = (await axios.get('/api/buying-offers')).data?.map(
          (r: any) => ({
            ...r,
            name: `#${r.id} ${r.name}`,
          }),
        )
      } else {
        this.offers = (await axios.get('/api/offers')).data?.map((r: any) => ({
          ...r,
          name: `#${r.id} ${r.name}`,
        }))
      }
    }
    if (this.$route.query.mobileAppId) {
      this.apps = (await axios.get('/api/tds-apps')).data?.map((r: any) => ({
        ...r,
        name: `#${r.id} ${r.name}`,
      }))
    }
    if (this.$route.query.campaignId) {
      if (isBuying(this)) {
        this.campaigns = (await axios.get('/api/buying-campaigns')).data?.map(
          (r: any) => ({
            ...r,
            name: `#${r.id} ${r.name}`,
          }),
        )
      } else {
        this.campaigns = (await axios.get('/api/campaigns')).data?.map(
          (r: any) => ({
            ...r,
            name: `#${r.id} ${r.name}`,
          }),
        )
      }
    }
    if (this.$route.query.trafficSourceId) {
      if (isBuying(this)) {
        this.trafficSources = (await axios.get('/api/traffic-sources')).data
      } else {
        this.trafficSources = (
          await axios.get('/api/traffic-sources/partners')
        ).data
      }
    }
    if (this.$route.query.affiliateId) {
      if (isBuying(this)) {
        this.affiliates = (
          await axios.get('/api/affiliates/list/buying')
        ).data?.map((affiliate: any) => ({
          ...affiliate,
          email: affiliate.credentials.email + ` [ID #${affiliate.id}]`,
        }))
      }
      if (isPartners(this)) {
        this.affiliates = (
          await axios.get('/api/affiliates/list/buying')
        ).data?.map((affiliate: any) => ({
          ...affiliate,
          email: `#${affiliate.id} ` + affiliate.credentials.email,
        }))
      }
      if (isAdminTds(this)) {
        this.affiliates = (
          await axios.get('/api/affiliates/list/all')
        ).data?.map((affiliate: any) => ({
          ...affiliate,
          email: !isPartners(this)
            ? `#${affiliate.id}  ` +
              (affiliate.name || affiliate.credentials.email)
            : `#${affiliate.id}  ` + affiliate.credentials.email,
        }))
      }
    }
    if (this.$q.screen.width < 600) {
      await initFilterOptions(this)
    }
  },
  computed: {
    isPartners(): boolean {
      return isPartners(this)
    },
    isBuying(): boolean {
      return isBuying(this)
    },
    isAdminTds(): boolean {
      return isAdminTds(this)
    },
    hideForConversions(): boolean {
      return !this.$route.path.includes('conversions')
    },
    hideForLandings(): boolean {
      return !this.$route.path.includes('landings')
    },
    hideForRotations(): boolean {
      return !this.$route.path.includes('rotations')
    },
    hideForTrafficSources(): boolean {
      return !this.$route.path.includes('traffic-sources')
    },
    hideForClickLogs(): boolean {
      return this.tab !== 'clicks'
    },
    hideForFinances(): boolean {
      return !this.$route.path.includes('finances')
    },
    hideForOffers(): boolean {
      return !this.$route.path.includes('offers')
    },
    hideForAffiliates(): boolean {
      return !this.$route.path.includes('affiliates')
    },
    hideForCampaigns(): boolean {
      return !this.$route.path.includes('campaigns')
    },
    hideForAdvertisers(): boolean {
      return !this.$route.path.includes('advertisers')
    },
    hideForPayouts(): boolean {
      return !this.$route.path.includes('payouts')
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForLogs(): boolean {
      return !this.$route.path.includes('clicklog')
    },
    hideForBalances(): boolean {
      return !this.$route.path.includes('balances')
    },
    hideForPostbackLogs(): boolean {
      return !this.$route.path.includes('postback-logs')
    },
    showForPushGroups(): boolean {
      return (
        this.$route.path.includes('push-notifications') &&
        this.tab === 'push-groups'
      )
    },
    showForPostbackBlackListTab(): boolean {
      return (
        !this.$route.path.includes('postback-logs') ||
        (this.$route.path.includes('postback-logs') &&
          this.tab === 'black-list-logs') ||
        (this.$route.path.includes('postback-logs') && this.tab === 'installs')
      )
    },
    hideForPushNotifications(): boolean {
      return !this.$route.path.includes('push-notifications')
    },
    hideForMobileAppsPage(): boolean {
      return !this.$route.path.includes('mobile-apps')
    },
  },
  data: function() {
    return {
      presetId: null,
      showFiltersDialog: false,
      filters: { ...this.currentFilters },
      ...getFiltersData(),
    }
  },
  watch: {
    presetId() {
      if (!this.presetId) {
        return
      }
      const presetFilters =
        (this.presets.find((p: any) => p.id === this.presetId) as any)
          ?.filters || {}
      this.filters = {
        ...this.filters,
        ...presetFilters,
      }
      this.filters.presetId = this.presetId
    },
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    ...mapMutations('authModule', ['setDateFilter']),
    onChangeFilters(field: string, value: string | null) {
      if (field === 'vertical') {
        this.filters.offerCategoryId = null
      }
      this.presetId = null
      this.filters[field] = value
    },
    resetFilters() {
      this.search = null
      this.$emit('reset')
    },
    async getOptionsByField(value: string, update: any, field: FieldOption) {
      await lookupByField(this, value, update, field)
    },
    async getAdvertisers(value: string, update: any, field: FieldOption) {
      await lookupAdvertiser(this, value, update)
    },
    async getCategories(value: string, update: any) {
      await lookupOfferCategories(this, value, update, [], {
        vertical: this.filters.vertical,
      })
    },
    async getOffers(value: string, update: any) {
      await lookupOffers(this, value, update)
    },
    async getPromos(value: string, update: any) {
      await lookupPromos(this, value, update)
    },
    async getTrafficSources(value: string, update: any) {
      await lookupTrafficSources(this, value, update)
    },
    async getCampaignsGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'campaign')
    },
    async getRotationsGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'rotation')
    },
    async getOfferGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'offer')
    },
    async getLandingGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'landing')
    },
    async getAffiliates(value: string, update: any) {
      await lookupAffiliates(this, value, update)
    },
    async getCampaigns(value: string, update: any) {
      await lookupCampaigns(this, value, update)
    },
    async getMobileApps(value: string, update: any) {
      await lookupMobileApps(this, value, update)
    },
    async getManagers(value: string, update: any) {
      await lookupManagers(this, value, update)
    },
    onChangeDate(date: string[]) {
      this.filters.dateFrom = date[0]
      this.filters.dateTo = date[1]
      this.setDateFilter({
        dateFrom: date[0],
        dateTo: date[1],
      })
    },
    onChangeConversionDate(date: string[]) {
      this.filters.conversionDateFrom = date[0]
      this.filters.conversionDateTo = date[1]
    },
    submitAll() {
      if (this.presetId) {
        this.filters = {
          ...this.filters,
          ...(this.presets.find((p: any) => p.id === this.presetId) as any)
            .filters,
        }
      }
      this.$emit('changeAll', this.filters)
    },
    submitMobileAll(filters: FiltersType) {
      this.$emit('changeAll', filters)
    },
    async deleteMarker(markerId: number) {
      confirmDialog(
        `Вы действительно хотите удалить маркет ID:${markerId}?`,
        async () => {
          await axios.delete('/api/markers', { params: { id: markerId } })
        },
      )
    },
  },
})
