
import { defineComponent } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  isMonday,
  previousMonday,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns'

export default defineComponent({
  name: 'DatePicker',
  props: ['from', 'to', 'changeDate'],
  components: { Datepicker },
  data: function() {
    function getCurrentWeek() {
      const date = new Date()
      if (isMonday(date)) {
        return [date, date]
      }
      return [previousMonday(date), date]
    }
    function getLastWeek() {
      const date = new Date()
      if (isMonday(date)) {
        return [sub(date, { days: 7 }), sub(date, { days: 1 })]
      }
      const dayOnPrevWeek = sub(date, { weeks: 1 })
      if (isMonday(dayOnPrevWeek)) {
        return [dayOnPrevWeek, endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 })]
      }
      return [
        previousMonday(dayOnPrevWeek),
        endOfWeek(dayOnPrevWeek, { weekStartsOn: 1 }),
      ]
    }

    const dateValue = [this.from, this.to]
    const customShortcuts = [
      {
        label: 'Сегодня',
        range: [new Date(), new Date()],
      },
      {
        label: 'Вчера',
        range: [sub(new Date(), { days: 1 }), sub(new Date(), { days: 1 })],
      },
      {
        label: 'Текущая неделя',
        range: getCurrentWeek(),
      },
      {
        label: 'Прошлая неделя',
        range: getLastWeek(),
      },
      {
        label: 'Текущий месяц',
        range: [startOfMonth(new Date()), new Date()],
      },
      {
        label: 'Прошлый месяц',
        range: [
          startOfMonth(sub(new Date(), { months: 1 })),
          endOfMonth(sub(new Date(), { months: 1 })),
        ],
      },
      {
        label: 'Текущий год',
        range: [startOfYear(new Date()), new Date()],
      },
      {
        label: 'Прошлый год',
        range: [
          startOfYear(sub(new Date(), { years: 1 })),
          endOfYear(sub(new Date(), { years: 1 })),
        ],
      },
      {
        label: 'Все время',
        range: [new Date('2022-05-01'), new Date()],
      },
    ]

    return {
      dateValue,
      customShortcuts,
    }
  },
  watch: {
    from() {
      this.dateValue[0] = this.from
    },
    to() {
      this.dateValue[1] = this.to
    },
    dateValue() {
      const from = this.formatDate(this.dateValue[0])
      const to = this.formatDate(this.dateValue[1])
      this.$emit('changeDate', [from, to])
    },
  },
  methods: {
    formatDate(date: any): string {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('/')
    },
  },
})
