import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createBlock(_component_q_page_container, { class: "page-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_Header, { onOpenDrawer: _ctx.openDrawer }, null, 8, ["onOpenDrawer"]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}