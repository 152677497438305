import axios, { AxiosError } from 'axios'
import { RewardType } from '@/enums/RewardType'
import { Vertical } from '@/enums/Vertical'
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';
import { Currency } from '@/enums/Currency'

const StringIsNumber = (value: string) => !isNaN(Number(value))
export interface EnumItem<E> {
  id: keyof E
  name: E
}

export type RowValue = string | null | number | undefined
export function enumToArray<E>(enumObject: {
  [key: string]: E
}): EnumItem<E>[] {
  return Object.keys(enumObject)
    .filter(StringIsNumber)
    .map(key => ({ id: Number(key), name: enumObject[key] } as EnumItem<E>))
}

export const getRewardTypesByVertical = (vertical: number | null) => {
  const rewardTypes = enumToArray(RewardType)
  switch (vertical) {
    case Vertical.Gambling:
      return rewardTypes.filter(({ name }) =>
        ['CPL', 'CPA', 'RevShare', 'BL'].includes(name as string),
      )
    default:
      return rewardTypes
  }
}

export const getFilteredCurrencies = () => {
  const rewardTypes = enumToArray(Currency)
  return rewardTypes.filter(({ name }) =>
    ['USD', 'EUR'].includes(name as string),
  )
}

export function isDefined(value: RowValue) {
  if (value === null || value === undefined) {
    return '—'
  }
  return value
}

export function formatValue(value: RowValue, round = 2, isMoney = false) {
  const formattedValue = isDefined(value)
  if (!isNaN(Number(formattedValue)) && isMoney) {
    return (+formattedValue).toFixed(round)
  }
  if (Number.isInteger(+formattedValue)) {
    return Number.parseInt(String(formattedValue))
  }
  if (formattedValue === '—') {
    return formattedValue
  }
  return Number((+formattedValue).toFixed(round))
}

export function formatDate(isoDate: string | Date): string {
  if (!isoDate) {
    return '—'
  }
  const date = new Date(isoDate)
  if (!date) {
    return '—'
  }
  return (
    date
      .toLocaleDateString()
      .split('/')
      .reverse()
      .join('/') +
    ' ' +
    date.toLocaleTimeString()
  )
}

export async function getOptions(
  options: any[],
  value: string,
  url: string,
  ids: number[] = [],
  extraParams: any = {},
  notIncludeIds: number[] = [],
): Promise<[]> {
  const selectedIds = ids.filter(Number)
  const notSelectedIds = notIncludeIds.filter(Number)
  if (
    (!options.length || value === '') &&
    (selectedIds.length || notSelectedIds.length)
  ) {
    return (
      await axios.get(url, {
        params: {
          ...extraParams,
          ids: selectedIds,
          notIncludeIds: notSelectedIds,
          ...(value === '' ? {} : { search: value }),
        },
      })
    ).data
  }

  if (!options.length || value === '') {
    return (await axios.get(url, { params: extraParams })).data
  }

  const params: any = { search: value }
  if (selectedIds.length) {
    params.ids = selectedIds
  }
  if (notSelectedIds.length) {
    params.notIncludeIds = notSelectedIds
  }

  if (value !== '') {
    return (await axios.get(url, { params: { ...params, ...extraParams } }))
      .data
  }

  return options as []
}

export function isNumberArraysEqual(
  firstArr: number[],
  secondArr: number[],
): boolean {
  if (!Array.isArray(firstArr) || !Array.isArray(secondArr)) {
    return false
  }
  return (
    JSON.stringify(firstArr.sort((a: number, b: number) => a - b)) ==
    JSON.stringify(secondArr.sort((a: number, b: number) => a - b))
  )
}

export function isPartners(ref: any) {
  if (!ref.$router) {
    return ref.vueRouter.currentRoute.value.fullPath.includes('buying')
  }
  return ref.$router.currentRoute.value.fullPath.includes('partners')
}

export function isBuying(ref: any) {
  if (!ref.$router) {
    return ref.vueRouter.currentRoute.value.fullPath.includes('buying')
  }
  return ref.$router.currentRoute.value.fullPath.includes('buying')
}

export function isAdminTds(ref: any) {
  if (!ref.$router) {
    return ref.vueRouter.currentRoute.value.fullPath.includes('buying')
  }
  return ref.$router.currentRoute.value.fullPath.includes('tds')
}

export function getApiPathBasedOnRoute(ref: any): string {
  if (isPartners(ref)) {
    return 'partners'
  }
  if (isBuying(ref)) {
    return 'buying'
  }
  if (isAdminTds(ref)) {
    return 'tds'
  }
  return ''
}

export async function downloadPromo(id: string) {
  const response: any = await axios.get(`/api/promos/${id}/download`)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = response.data.url
  document.body.appendChild(link)
  link.click()
  setTimeout(() => {
    URL.revokeObjectURL(link.href)
    ;(link.parentNode as any).removeChild(link)
  }, 0)
}

export function showErrorSnackBar(err: AxiosError) {
  if (err.response?.status === 500) {
    Notify.create({
      type: 'negative',
      timeout: 3000,
      position: 'top-right',
      message: `Internal Server Error: ${err.message}`,
    })
  }
}

export function showSuccessSnackBar(message: string) {
  Notify.create({
    type: 'positive',
    timeout: 3000,
    position: 'top-right',
    message,
  })
}

export function confirmDialog(message: string, okFn: any) {
  Dialog.create({
    title: 'Подтвердите действие',
    message,
    cancel: true,
    persistent: true,
  }).onOk(okFn)
}

export function showErrorMessageSnackBar(message: string) {
  Notify.create({
    type: 'negative',
    timeout: 3000,
    position: 'top-right',
    message,
  })
}

export function randomString(length: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const campaignRoutes = [
  'sp',
  'reg',
  'r',
  'e9ZqMY',
  'Fj3MXi',
  'AJIOSj',
  'th9Oyi',
  '7mTNSO',
  'iLDATa',
  'fo1K6T',
  'qc3I9z',
  'Ue1oxQ',
  'KWamNW',
  '3dnjGp',
  'UT19kU',
  'dvPFGg',
  'Hbdf7J',
  'NoP2G1',
  's6OBCq',
  'GnFEoT',
  'cctEN5',
  'SMDzSU',
  'je6xYZ',
  'YzebqX',
  'FnvtTC',
  'mjwibz',
  '2hWbg3',
  'sRZNqT',
  'h61bFI',
  'usQSWG',
  'KqknEM',
  'RcICmk',
  'L6C8Tj',
  'FQRfDh',
  'm9FwdE',
  'wWiHsD',
  'CeHZ0c',
  'kPl6hB',
  'UCn0RD',
  'ilqZgs',
  'JGqtSI',
  'jlr3LN',
  'ytPMTY',
  'gN8GgZ',
  '4CW3TV',
  'XtMQWA',
  '91iY2Q',
  'xNGHQ7',
  '1SDhT2',
  'HU1j2i',
  'uM5JAU',
  '7d9BDb',
  'JYT1yy',
  'acaVGD',
  'tRG4fU',
  'KfSeq6',
  'TerIJj',
  'CjKrOB',
  'i0528v',
  'XG0npW',
  'nQCVT9',
  'srArhx',
  '0PERC0',
  'M8GO6R',
  'O1kaNb',
  'w7NjQJ',
  'uCjyNr',
  'v2OiBN',
  'YIMLCn',
  'DCd5Ng',
  'w97pG4',
  'h37wGv',
  'ckug5X',
  'm5nO1J',
  'BLIJZY',
  'WzfJbo',
  'hm3kst',
  'fNr7du',
  'jMwEqe',
  '36gSbp',
  'OBQb2n',
  'RJcsXB',
  'ZJiRk8',
  'UPgqZq',
  '4wWRBj',
  'A8lSKT',
  'wZpEtC',
  'xwghdp',
  'wm41kc',
  'LksLw2',
  'WAs2ar',
  'b5RLCt',
  'ffQ1Ul',
  'YzqrQB',
  'RW4D3F',
  'TUNuZP',
  'rVB74Z',
  'V1Vr1F',
  'ZbPQwQ',
  'hWK8TX',
  'QJAKAV',
  'GTB19a',
  'b8cAHr',
]
