import { enumToArray } from '@/utils'
import { RewardType } from '@/enums/RewardType'
import { PostbackEventType } from '@/enums/PostbackEventType'
import { Vertical } from '@/enums/Vertical'
import axios from 'axios'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { OfferStatus } from '@/enums/OfferStatus'
import { CampaignDepartment } from '@/enums/CampaignDepartment'
import { MobileAppStatus } from '@/enums/MobileAppStatus'
import { AffiliateStatus } from '@/enums/AffiliateStatus'

export function getFiltersData(search = null) {
  return {
    promos: [],
    presets: [],
    offers: [],
    campaigns: [],
    rotations: [],
    admins: [],
    geos: [],
    categories: [],
    affiliates: [],
    advertisers: [],
    markers: [],
    groups: [],
    search,
    apps: [],
    trafficSources: [],
    campaignStatuses: enumToArray(CampaignStatus),
    rewardTypes: enumToArray(RewardType),
    postbackEventTypes: enumToArray(PostbackEventType),
    verticals: enumToArray(Vertical),
    offerStatuses: enumToArray(OfferStatus),
    departments: enumToArray(CampaignDepartment),
    affiliateStatuses: enumToArray(AffiliateStatus),
    mobileAppStatuses: enumToArray(MobileAppStatus),
  }
}

export async function initFilterOptions(ref: any) {
  ref.markers = (await axios.get('/api/markers')).data
  ref.apps = (await axios.get('/api/tds-apps')).data?.map((r: any) => ({
    ...r,
    name: `#${r.id} ${r.name}`,
  }))
  ref.campaigns = (await axios.get('/api/campaigns')).data?.map((r: any) => ({
    ...r,
    name: `#${r.id} ${r.name}`,
  }))
  ref.rotations = (await axios.get('/api/rotations')).data?.map((r: any) => ({
    ...r,
    name: `#${r.id} ${r.name}`,
  }))
  ref.offers = (await axios.get('/api/offers')).data?.map((r: any) => ({
    ...r,
    name: `#${r.id} ${r.name}`,
  }))
  ref.geos = (await axios.get('/api/geos/')).data
  ref.advertisers = (await axios.get('/api/advertisers/')).data?.map(
    (r: any) => ({
      ...r,
      name: `#${r.id} ${r.name}`,
    }),
  )
  const affiliates = (await axios.get('/api/affiliates/all')).data
  ref.affiliates = affiliates.map((affiliate: any) => ({
    ...affiliate,
    email: affiliate.affiliateEmail,
  }))
  ref.promos = (await axios.get('/api/promos')).data
  // ref.apps = (await axios.get('/api/apps/')).data
  ref.trafficSources = (await axios.get('/api/traffic-sources/')).data
}
