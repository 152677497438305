import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex cursor-pointer",
  style: {"width":"237px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Datepicker, {
      range: "",
      required: "",
      presetRanges: _ctx.customShortcuts,
      placeholder: "Выбрать дату",
      modelValue: _ctx.dateValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateValue) = $event)),
      format: "yyyy/MM/dd",
      weekNumbers: "",
      weekNumName: "W#",
      enableTimePicker: false,
      clearable: false,
      selectText: "Выбрать",
      cancelText: "Отмена",
      maxDate: new Date(),
      minDate: "2022/04/01"
    }, null, 8, ["presetRanges", "modelValue", "maxDate"])
  ]))
}