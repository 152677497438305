import router from '@/router'
import axios from 'axios'
import { ActionContext } from 'vuex'
import {
  AuthState,
  LoginData,
  RootModule,
  SignUpData,
  User,
  BeginPasswordReset,
  EndPasswordReset,
} from '@/store/types'

const user = JSON.parse(localStorage.getItem('user') as string)
const initialState = user
  ? { status: { loggedIn: true }, user, loginError: false, signUpError: false }
  : {
      status: { loggedIn: false },
      user: null,
      loginError: false,
      signUpError: false,
    }

const authModule = {
  namespaced: true,
  state: initialState,
  actions: {
    async login(
      { commit }: ActionContext<AuthState, RootModule>,
      { email, password }: LoginData,
    ) {
      try {
        const { data } = await axios.post('/api/auth/login', {
          email,
          password,
        })
        const config = {
          headers: { Authorization: `Bearer ${data.accessToken}` },
        }
        const user = await axios.get('api/auth/profile', config)
        commit('loginSuccess', { ...user.data, ...data, email })
        await router.push('/tds/clicklog')
      } catch (err) {
        commit('loginFailure')
        throw err
      }
    },
    async startPasswordReset(
      { commit }: ActionContext<AuthState, RootModule>,
      { email }: BeginPasswordReset,
    ) {
      try {
        await axios.post('/api/auth/reset-password', { email })
        commit('passwordResetSuccess')
      } catch (e) {
        commit('passwordResetFailure')
        throw e
      }
    },
    async finishPasswordReset(
      { commit }: ActionContext<AuthState, RootModule>,
      { key, newPassword }: EndPasswordReset,
    ) {
      try {
        await axios.put('/api/auth/reset-password', { newPassword, key })
        commit('passwordResetSuccess')
        await router.push('/login')
      } catch (e) {
        commit('passwordResetFailure')
      }
    },
    async tryImpersonate({ commit }: ActionContext<AuthState, RootModule>) {
      const urlParams = new URLSearchParams(location.search)
      const key = urlParams.get('key')
      if (!key) {
        return
      }
      const { data } = await axios.post('/api/auth/impersonate', { key })
      const config = {
        headers: { Authorization: `Bearer ${data.accessToken}` },
      }
      const user = await axios.get('api/auth/profile', config)
      commit('loginSuccess', { ...user.data, ...data })
      await router.push('/tds/clicklog')
    },
    async signUp(
      { commit }: ActionContext<AuthState, RootModule>,
      { email, password, telegram, ref }: SignUpData,
    ) {
      try {
        await axios.post(
          '/api/auth/sign-up',
          {
            email,
            password,
            telegram,
            ref,
          },
          { params: { ...router.currentRoute.value.query } },
        )
      } catch (err) {
        commit('signUpFailure')
        throw err
      }
    },
    async logout({ commit }: ActionContext<AuthState, RootModule>) {
      await axios.post('/api/auth/logout')
      commit('logout')
    },
  },
  mutations: {
    loginSuccess(state: AuthState, user: User) {
      state.status = { loggedIn: true }
      state.user = user
      state.loginError = false
      state.signUpError = false
      localStorage.setItem('user', JSON.stringify(user))
    },
    loginFailure(state: AuthState) {
      state.status = { loggedIn: false }
      state.user = null
      state.loginError = true
    },
    signUpFailure(state: AuthState) {
      state.signUpError = true
    },
    passwordResetSuccess(state: AuthState) {
      state.passwordResetError = false
    },
    passwordResetFailure(state: AuthState) {
      state.passwordResetError = true
    },
    logout(state: AuthState) {
      state.status = { loggedIn: false }
      state.user = null
      localStorage.removeItem('user')
      router.push('/login').catch(err => {
        throw err
      })
    },
    resetErrors(state: AuthState) {
      state.loginError = false
      state.signUpError = false
    },
  },
}

export default authModule
