<template>
  <q-header
    :style="isStartView ? 'background: transparent;' : 'background: #252524;'"
  >
    <q-toolbar>
      <q-btn flat @click="$emit('openMenu')" round dense icon="menu" />
      <q-toolbar-title class="cursor-pointer flex items-center">
        <img src="../../../public/images/mt.svg" height="40" width="40" />
      </q-toolbar-title>
      <q-btn
        no-caps
        size="13px"
        label="Выход"
        @click="logout"
        outline
        color="white"
        icon-right="logout"
      />
    </q-toolbar>
  </q-header>
</template>

<script>
import { mapActions } from 'vuex'
import { isAdminTds, isBuying, isPartners } from '@/utils'

export default {
  name: 'Header',
  props: ['openMenu'],
  computed: {
    isPartners() {
      return isPartners(this)
    },
    isBuying() {
      return isBuying(this)
    },
    isAdminTds() {
      return isAdminTds(this)
    },
    isStartView() {
      return !isPartners(this) && !isBuying(this) && !isAdminTds(this)
    },
  },
  methods: {
    ...mapActions('authModule', ['logout']),
    async clearCache() {
      this.$storage.removeStorageSync('currentPage')
      this.$storage.removeStorageSync('statsPagination')
      this.$storage.removeStorageSync('buyingFilters')
      this.$storage.removeStorageSync('partnersFilters')
      this.$storage.removeStorageSync('tdsFilters')
      this.$storage.removeStorageSync('expires')
      window.location.reload(true)
    },
  },
}
</script>
