<template>
  <q-layout
    view="hHh Lpr lff"
    :style="isStartView ? 'background: #000 none repeat scroll 0% 0%;' : ''"
  >
    <router-view />
  </q-layout>
</template>

<script>
export default {
  name: 'AppsAdmin',
  computed: {
    isStartView() {
      return this.$router.currentRoute.value.fullPath === '/'
    },
  },
}
</script>
