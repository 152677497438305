
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  name: 'Login',
  computed: mapState('authModule', ['error']),
  data: function() {
    return {
      email: null,
      password: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions('authModule', ['login']),
    async submit() {
      this.loading = true
      try {
        await this.login({
          email: this.email,
          password: this.password,
        })
      } finally {
        this.loading = false
      }
    },
  },
})
