
import { defineComponent } from 'vue'
import DatePicker from '@/components/date-picker/DatePicker.vue'
import { getFiltersData, initFilterOptions } from '@/utils/filters'
import {
  lookupCampaigns,
  lookupOfferCategories,
  lookupAffiliates,
  lookupByField,
  FieldOption,
  lookupMobileApps,
  lookupOffers,
  lookupPromos,
  lookupTrafficSources,
  lookupGroups,
} from '@/utils/lookups'
import { getDefaultFilters } from '@/utils/request'
import { isAdminTds, isBuying, isPartners } from '@/utils'

export default defineComponent({
  name: 'FiltersDialog',
  components: { DatePicker },
  props: ['open', 'close', 'reset', 'currentFilters', 'changeFilters', 'tab'],
  async created() {
    await initFilterOptions(this)
  },
  computed: {
    isPartners(): boolean {
      return isPartners(this)
    },
    isBuying(): boolean {
      return isBuying(this)
    },
    isAdminTds(): boolean {
      return isAdminTds(this)
    },
    showDialog(): boolean {
      return this.open
    },
    hideForConversions(): boolean {
      return !this.$route.path.includes('conversions')
    },
    hideForLandings(): boolean {
      return !this.$route.path.includes('landings')
    },
    hideForTrafficSources(): boolean {
      return !this.$route.path.includes('traffic-sources')
    },
    hideForClickLogs(): boolean {
      return this.tab !== 'clicks'
    },
    hideForFinances(): boolean {
      return !this.$route.path.includes('finances')
    },
    hideForOffers(): boolean {
      return !this.$route.path.includes('offers')
    },
    hideForAffiliates(): boolean {
      return !this.$route.path.includes('affiliates')
    },
    hideForRotations(): boolean {
      return !this.$route.path.includes('rotations')
    },
    hideForCampaigns(): boolean {
      return !this.$route.path.includes('campaigns')
    },
    hideForAdvertisers(): boolean {
      return !this.$route.path.includes('advertisers')
    },
    hideForPayouts(): boolean {
      return !this.$route.path.includes('payouts')
    },
    hideForStatistics(): boolean {
      return !this.$route.path.includes('statistics')
    },
    hideForLogs(): boolean {
      return !this.$route.path.includes('clicklog')
    },
    hideForBalances(): boolean {
      return !this.$route.path.includes('balances')
    },
    hideForPostbackLogs(): boolean {
      return !this.$route.path.includes('postback-logs')
    },
    showForPushGroups(): boolean {
      return (
        this.$route.path.includes('push-notifications') &&
        this.tab === 'push-groups'
      )
    },
    showForPostbackBlackListTab(): boolean {
      return (
        !this.$route.path.includes('postback-logs') ||
        (this.$route.path.includes('postback-logs') &&
          this.tab === 'black-list-logs') ||
        (this.$route.path.includes('postback-logs') && this.tab === 'installs')
      )
    },
    hideForPushNotifications(): boolean {
      return !this.$route.path.includes('push-notifications')
    },
    hideForMobileAppsPage(): boolean {
      return !this.$route.path.includes('mobile-apps')
    },
  },
  data: function() {
    return {
      filters: { ...this.currentFilters },
      ...getFiltersData(this.currentFilters.name),
    }
  },
  watch: {
    search() {
      this.onChangeFilters('name', this.search)
    },
    currentFilters() {
      this.filters = { ...this.currentFilters }
      this.search = this.currentFilters.name
    },
  },
  methods: {
    addFilters() {
      this.$emit('changeFilters', this.filters)
      this.$emit('close')
    },
    resetFilters() {
      this.search = null
      this.filters = getDefaultFilters(true)
      this.$emit('changeFilters', this.filters)
    },
    onChangeFilters(field: string, value: string | null) {
      if (field === 'vertical') {
        this.filters.offerCategoryId = null
      }
      this.filters[field] = value
    },
    onChangeDate(date: string[]) {
      this.filters.dateFrom = date[0]
      this.filters.dateTo = date[1]
    },
    onChangeConversionDate(date: string[]) {
      this.filters.conversionDateFrom = date[0]
      this.filters.conversionDateTo = date[1]
    },
    async getOptionsByField(value: string, update: any, field: FieldOption) {
      await lookupByField(this, value, update, field)
    },
    async getCategories(value: string, update: any) {
      await lookupOfferCategories(this, value, update, [], {
        vertical: this.filters.vertical,
      })
    },
    async getAffiliates(value: string, update: any) {
      await lookupAffiliates(this, value, update)
    },
    async getCampaigns(value: string, update: any) {
      await lookupCampaigns(this, value, update)
    },
    async getMobileApps(value: string, update: any) {
      await lookupMobileApps(this, value, update)
    },
    async getOffers(value: string, update: any) {
      await lookupOffers(this, value, update)
    },
    async getPromos(value: string, update: any) {
      await lookupPromos(this, value, update)
    },
    async getTrafficSources(value: string, update: any) {
      await lookupTrafficSources(this, value, update)
    },
    async getCampaignsGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'campaign')
    },
    async getRotationsGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'rotation')
    },
    async getOfferGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'offer')
    },
    async getLandingGroups(value: string, update: any) {
      await lookupGroups(this, value, update, 'landing')
    },
  },
})
