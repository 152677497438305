import { getData } from '@/utils/getData'
import { add, format } from 'date-fns'
import { MarkupType } from '@/enums/MarkupType'
import { TrafficPropertyType } from '@/enums/TrafficPropertyType'
import { Vertical } from '@/enums/Vertical'
import { RewardType } from '@/enums/RewardType'
import { CampaignStatus } from '@/enums/CampaignStatus'
import { showErrorMessageSnackBar } from '@/utils/index'
import { CampaignDepartment } from '@/enums/CampaignDepartment'
import { useStorage } from 'vue3-storage'
import router from '@/router'
import { pick } from 'lodash'

export type ReadOptions = {
  filters: Filters
  groupBy?: GroupBy
  orderBy?: OrderBy
  limit?: number
  offset?: number
}

export type GroupByOptions = Array<{
  id: string
  name: string
  field: string
}>

export type FiltersType = {
  offerId?: number
  campaignId?: number
  landingId?: number
  preLandingId?: number
  geoId?: number
  status?: CampaignStatus
  markupType?: number
  dateFrom?: number | string
  dateTo?: number | string
  groupBy?: string
}

export type Filters = {
  affiliateId?: number
  offerId?: number
  campaignId?: number
  landingId?: number
  preLandingId?: number
  geoId?: number
  markupType?: MarkupType
  dateFrom?: number | string
  dateTo?: number | string
  trafficProperty?: TrafficPropertyType
  vertical?: Vertical
  advertiserId?: number
  categoryId?: number
  rewardType?: RewardType
  groupBy?: string
  groupBy2?: string
  groupBy3?: string
}

type GroupBy = string[]
type OrderBy = { [key: string]: string }
export type Row = { [key: string]: any }
export type Column = { [key: string]: any }

type Pagination = {
  page: number
  rowsNumber: number
  rowsPerPage: number
  sortBy: string
  descending: boolean
}
export type Table = {
  loading: boolean
  rows: string[]
  pagination: Pagination
  filters?: Filters
}
export type TableRequestProps = {
  pagination: Pagination
  row?: Row
}

export function getPagination(
  sortBy: string,
  rowsPerPage: number,
  descending = true,
) {
  return {
    sortBy,
    descending,
    page: 1,
    rowsPerPage,
    rowsNumber: 100,
  }
}

export const getTableProperties = (
  sortBy: string,
  rowsPerPage = 10,
  descending = true,
) => ({
  rows: [],
  loading: true,
  pagination: getPagination(sortBy, rowsPerPage, descending),
})

export function getDefaultFilters(fullReset = false): any {
  const date = new Date()
  const today = new Date()
  const dateTo = format(date, 'yyyy/MM/dd')
  const dateFrom = format(today, 'yyyy/MM/dd')
  const storage = useStorage()
  const defaultFilters = {
    groupBy: 'date',
    dateFrom,
    dateTo,
  }
  // проверка истечения сессии
  const expires = storage.getStorageSync('expires')
  if (!expires) {
    storage.setStorageSync('expires', add(new Date(), { hours: 8 }))
  } else if (new Date(expires).getTime() < new Date().getTime()) {
    storage.removeStorageSync('buyingFilters')
    storage.removeStorageSync('partnersFilters')
    storage.removeStorageSync('tdsFilters')
    storage.setStorageSync('expires', add(new Date(), { hours: 8 }))
  }

  const isBuying = router.currentRoute?.value?.fullPath?.includes('buying')
  const isPartners = router.currentRoute?.value?.fullPath?.includes('partners')
  const isAdminTds = router.currentRoute?.value?.fullPath?.includes('tds')

  const buyingFilters = storage.getStorageSync('buyingFilters')
  const partnersFilters = storage.getStorageSync('partnersFilters')
  const tdsFilters = storage.getStorageSync('tdsFilters')

  if (buyingFilters && isBuying && !fullReset) {
    return buyingFilters
  }
  if (partnersFilters && isPartners && !fullReset) {
    return partnersFilters
  }
  if (tdsFilters && isAdminTds && !fullReset) {
    return tdsFilters
  }

  if (isBuying) {
    storage.setStorageSync('buyingFilters', defaultFilters)
  }
  if (isPartners) {
    storage.setStorageSync('partnersFilters', defaultFilters)
  }
  if (isAdminTds) {
    storage.setStorageSync('tdsFilters', defaultFilters)
  }

  return defaultFilters
}

export function getGroupByField(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: Filters,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        case 'department':
          return CampaignDepartment[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}

export function getGroupBy2Field(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: Filters,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy2) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        case 'department':
          return CampaignDepartment[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}

export function getGroupBy3Field(
  key: 'id' | 'name' | 'field',
  row: Row,
  groupByOptions: GroupByOptions,
  filters: Filters,
): string | undefined {
  for (const option of groupByOptions) {
    if (option.id !== filters.groupBy3) {
      continue
    }
    const fieldName = option[key]
    if (key === 'field') {
      switch (fieldName) {
        case 'rewardType':
          return RewardType[Number(row[fieldName])]
        case 'vertical':
          return Vertical[Number(row[fieldName])]
        case 'department':
          return CampaignDepartment[Number(row[fieldName])]
        default:
          return row[fieldName]
      }
    }
    return fieldName
  }
}

export async function request(
  props: TableRequestProps,
  ref: any,
  url: string,
  withoutPagination = false,
) {
  const storage = useStorage()
  const isBuying = router.currentRoute?.value?.fullPath?.includes('buying')
  const isPartners = router.currentRoute?.value?.fullPath?.includes('partners')
  if (isBuying) {
    storage.setStorageSync(
      'buyingFilters',
      pick(ref.filters, [
        'groupBy',
        'groupBy2',
        'groupBy3',
        'dateFrom',
        'dateTo',
        'department',
        'campaignStatus',
        'affiliateId',
        'geoId',
      ]),
    )
  } else if (isPartners) {
    storage.setStorageSync(
      'partnersFilters',
      pick(ref.filters, [
        'groupBy',
        'groupBy2',
        'groupBy3',
        'dateFrom',
        'dateTo',
        'department',
        'campaignStatus',
        'affiliateId',
        'geoId',
      ]),
    )
  } else {
    storage.setStorageSync(
      'tdsFilters',
      pick(ref.filters, [
        'groupBy',
        'groupBy2',
        'groupBy3',
        'dateFrom',
        'dateTo',
        'department',
        'campaignStatus',
        'affiliateId',
        'geoId',
      ]),
    )
  }

  const { page, rowsPerPage, sortBy, descending } = props.pagination
  ref.loading = true
  const limit = withoutPagination
    ? 0
    : rowsPerPage === 0
    ? ref.pagination.rowsNumber
    : rowsPerPage
  const offset = (page - 1) * rowsPerPage
  if (!ref.filters?.groupBy) {
    if (!ref.filters) {
      ref.filters = getDefaultFilters(true)
    }
    ref.filters.groupBy = 'date'
  }
  const orderBy = {
    [sortBy !== 'groupByField'
      ? sortBy
      : String(ref.filters?.groupBy)]: descending ? 'DESC' : 'ASC',
  }
  try {
    ref.rows = await getData(
      { filters: ref.filters || {}, limit, offset, orderBy },
      url,
    )
  } catch (e) {
    showErrorMessageSnackBar('Произошла ошибка запроса.')
  }
  if (!ref.pagination) {
    ref.pagination = getPagination('groupByField', 30)
  }
  ref.pagination.rowsNumber = (ref.rows.length
    ? ((ref.rows[0] as unknown) as Row).total
    : ref.pagination.rowsNumber) as number
  ref.pagination.page = page
  ref.pagination.rowsPerPage = withoutPagination
    ? ref.pagination.rowsNumber
    : rowsPerPage
  ref.pagination.sortBy = sortBy
  ref.pagination.descending = descending
  ref.loading = false
  return ref.rows
}
