import Login from '@/pages/login/Login.vue'
import DashboardView from '@/views/DashboardView.vue'
import PostbackLogs from '@/pages/tds-request-logs/PostbackLogs.vue'

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: DashboardView,
    name: 'basic',
    meta: { requiresAuth: true },
    children: [
      { path: '/tds/clicklog', component: PostbackLogs, name: 'tds-logs' },
    ],
  },
]

export default routes
