
import { defineComponent } from 'vue'
import Installs from './Installs.vue'
import BlackListLogs from './BlackListLogs.vue'
export default defineComponent({
  name: 'Logs',
  components: {
    Installs,
    BlackListLogs,
  },
  data: function() {
    return {
      tab: 'installs',
      showPostbackNoteDialog: false,
    }
  },
})
