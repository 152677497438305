import { createStore } from 'vuex'
import authModule from './modules/auth.module'

const store = createStore({
  modules: {
    authModule,
  },
})

export default store
