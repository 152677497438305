import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes'
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.state.authModule.status?.loggedIn

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next()
})

export default router
