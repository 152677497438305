import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router'
import store from './store'
import quasarOptions from './configs/quasar-options'

createApp(App)
  .use(Quasar, quasarOptions)
  .use(router)
  .use(store)
  .mount('#app')
